<template>
  <div>
    <v-text-field dense readonly v-if="!editMode" v-model="date" :label="label" />
    <v-menu v-if="editMode" ref="pickerMenu" v-model="pickerMenu" :close-on-content-click="false" transition="scale-transition"
            offset-y min-width="290px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-text-field v-model="date" dense readonly :label="label" prepend-inner-icon="mdi-calendar" v-bind="attrs" :rules="rules.generalDate" v-on="on" />
      </template>
      <v-date-picker v-model="date" no-title locale="ko-kr" :allowed-dates="allowedDates" @input="pickDate">
        <v-spacer />
        <v-btn text color="primary" @click="pickerMenu = false">취소</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import rules from '@/utils/validationRules'

export default {
  name: 'DatePicker',
  props: {
    editMode: { type: Boolean, default: false },
    model: { default: null },
    label: { type: String, default: '' },
    allowedDates: { type: Function, default: () => {} }
  },
  data() {
    return {
      date: this.model,
      pickerMenu: false,
      rules: {
        ...rules,
      }
    }
  },
  methods: {
    pickDate() {
      this.$emit('select', this.date)
      this.pickerMenu = false
    }
  },
  mounted() {
    this.date = this.model
  },
  watch: {
    model() {
      this.date = this.model
    }
  }
}
</script>
