import i18n from '@/lang'

export const Required = function() { return (v) => !!v || i18n.t('validate.required') }
export const Between = function(min, max) { return (v) => (v && v.length >= min && v.length <= max) || i18n.t('validate.between', [min, max]) }
export const AlphaOnly = function() { return (v) => (v && /^[a-zA-Z]*$/.test(v)) || i18n.t('validate.alphaOnly') }
export const DigitOnly = function() { return (v) => (v === 0 || (v && /^-?\d*(.\d+)?$/.test(v))) || i18n.t('validate.digitOnly') }
export const NullOrMax = function(max) { return (v) => (v && v !== '') ? ((v && v.length <= max) || i18n.t('validate.max', [max])) : true }
export const NullOrMin = function(min) { return (v) => (v && v !== '') ? ((v && v.length >= min) || i18n.t('validate.min', [min])) : true }
export const MinMaxValue = function(min, max) { return (v) => ((min === 0 && v === 0) || (v && v >= min && v <= max)) || i18n.t('validate.minMaxValue', [min, max]) }
export const NullOrMinValue = function(min) { return (v) => (v && v !== '') ? ((v && v >= min) || i18n.t('validate.minValue', [min])) : true }
export const PositiveNumber = function() { return (v) => (v === 0 || (v && /^[0-9]*$/.test(v))) || i18n.t('validate.positiveNumber') }
export const Date = function() { return (v) => (v === 0 || (v && /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/.test(v))) || i18n.t('validate.date') }
export const Date6 = function() { return (v) => (v === 0 || (v && /[0-9]{6}/.test(v))) || i18n.t('validate.date') }
export const Year = function() { return (v) => (v && v !== '') ? (v && /^[0-9]{4}$/.test(v)) || i18n.t('validate.date') : true }
export const Choose = function() { return (v) => Object.keys(v).length !== 0 || i18n.t('validate.choose') }
export default {
  loginUsername: [
    Required(),
    Between(4, 50)
  ],
  loginPassword: [
    Required(),
    Between(4, 50)
  ],
  generalName: [
    Required(),
    AlphaOnly(),
    Between(2, 50)
  ],
  generalDescription: [
    NullOrMax(2048)
  ],
  generalRequiredText: [
    Required(),
    Between(1, 255)
  ],
  generalNumber: [
    Required(),
    DigitOnly(),
    PositiveNumber()
  ],
  generalDate: [
    Required(),
    Date()
  ],
  propertyKey: [
    Required(),
    Between(4, 50),
    v => (v && /^[a-zA-Z0-9.-]*$/.test(v)) || i18n.t('validate.key')
  ],
  numAndAlphaOnly: [
    Required(),
    Between(1, 2),
    v => (v && /^[a-zA-Z0-9]*$/.test(v)) || i18n.t('validate.numAndAlphaOnly')
  ],
  onlyAlphaNumDotMin: [
    Required(),
    NullOrMax(20),
    v => (v && /^[a-zA-Z0-9][a-zA-Z0-9.-]*$/.test(v)) || i18n.t('validate.key')
  ],
  requiredAndMax100: [Required(), NullOrMax(100)],
  nullOrMax20: [NullOrMax(20)],
  nullOrMax50: [NullOrMax(50)],
  nullOrMax100: [NullOrMax(100)],
  required: [Required()],
  between1to50: [Between(1, 50)],
  between4to10: [Between(4, 10)],
  choose: [Choose()]
}
